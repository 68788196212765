<template>
  <div>
    <b-tabs>
      <b-tab active title="English">
        <b-card title="Concession Document Details">
          <validation-observer ref="basicValidation">
            <b-form>
              <b-row>
                <b-col md="4">
                  <b-form-group label="Page Title">
                    <validation-provider
                      #default="{ errors }"
                      name="Page Title"
                      rules="required"
                    >
                      <b-form-input
                        v-model="page.pageTitle"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Page Title"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label="Agency List">
                    <b-form-input
                      v-model="page.title"
                      placeholder="Agency List"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label="Download Url">
                    <b-form-input
                      v-model="page.downloadUrl"
                      placeholder="Download Url"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-form-group label="Description">
                    <quill-editor
                      v-model="page.description"
                      :options="snowOption"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card>
      </b-tab>

      <b-tab title="Hindi">
        <b-card title="Concession Document Details">
          <b-form>
            <b-row>
              <b-col md="4">
                <b-form-group label="Page Title">
                  <b-form-input
                    v-model="page.translation.hi.pageTitle"
                    placeholder="Page Title"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Agency List">
                  <b-form-input
                    v-model="page.translation.hi.title"
                    placeholder="Agency List"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Download Url">
                  <b-form-input
                    v-model="page.translation.hi.downloadUrl"
                    placeholder="Download Url"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Description">
                  <quill-editor
                    v-model="page.translation.hi.description"
                    :options="snowOption"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-tab>

      <b-tab title="Marathi">
        <b-card title="Concession Document Details">
          <b-form>
            <b-row>
              <b-col md="4">
                <b-form-group label="Page Title">
                  <b-form-input
                    v-model="page.translation.mr.pageTitle"
                    placeholder="Page Title"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Agency List">
                  <b-form-input
                    v-model="page.translation.mr.title"
                    placeholder="Agency List"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="4">
                <b-form-group label="Download Url">
                  <b-form-input
                    v-model="page.translation.mr.downloadUrl"
                    placeholder="Download Url"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Description">
                  <quill-editor
                    v-model="page.translation.mr.description"
                    :options="snowOption"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-tab>
    </b-tabs>
    <b-card>
      <b-button
        @click="updateConcession"
        size="lg"
        variant="primary"
        block
        >Update Concession Document</b-button
      >
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import Ripple from "vue-ripple-directive";
import { required } from "@validations";
import { getUserToken } from "@/auth/utils";
import {
  BCard,
  BRow,
  BCol,
  BTabs,
  BTab,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BFormTextarea,
  BFormFile,
  BCardText,
} from "bootstrap-vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

import axios from "axios";
export default {
  directives: {
    Ripple,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BForm,
    BTabs,
    BTab,
    BFormInput,
    BFormGroup,
    quillEditor,
    BButton,
    BFormTextarea,
    BFormFile,
    BCardText,
  },
  data() {
    return {
      required,
      file: [],
      snowOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote"],
            ["link"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ align: [] }],
            ["clean"],
          ],
        },
      },
      uploadErrors: [],
      concessionId: "",
      page: {
        pageTitle: "",
        description: "",
        title: "",
        downloadUrl: "",
        translation: {
          hi: {
            pageTitle: "",
            description: "",
            title: "",
            downloadUrl: "",
          },
          mr: {
            pageTitle: "",
            description: "",
            title: "",
            downloadUrl: "",
          },
        },
      },
    };
  },
  mounted() {
    this.getDocument();
  },
  methods: {
    getDocument() {
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/pages/concession/all/${this.$route.params.slug}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          this.page = response.data;
          this.concessionId = response.data._id;
          const { translation } = response.data;
          this.page.translation = {
            hi: {
              pageTitle:
                translation && translation.hi.pageTitle
                  ? translation.hi.pageTitle
                  : "",
              description:
                translation && translation.hi.description
                  ? translation.hi.description
                  : "",
              title:
                translation && translation.hi.title ? translation.hi.title : "",
              downloadUrl:
                translation && translation.hi.downloadUrl
                  ? translation.hi.downloadUrl
                  : "",
            },
            mr: {
              pageTitle:
                translation && translation.mr.pageTitle
                  ? translation.mr.pageTitle
                  : "",
              description:
                translation && translation.mr.description
                  ? translation.mr.description
                  : "",
              title:
                translation && translation.mr.title ? translation.mr.title : "",
              downloadUrl:
                translation && translation.mr.downloadUrl
                  ? translation.mr.downloadUrl
                  : "",
            },
          };
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast(
            "danger",
            "Error",
            "Error while fetching the Document!"
          );
        });
    },
    makeToast(variant, title, content) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    updateConcession() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/pages/concession/update`,
            { 'concessionID': this.concessionId, 'updates': this.page },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          console.log('updates', response.data);
          this.makeToast(
            "success",
            "Success",
            `${response.data.title} has been created successfully!`
          );
        })
        .catch((error) => {
          console.log(error.response);
          this.makeToast("danger", "Error", "Error while adding the Document!");
        });
    },
    uploadCSV() {
      const csvFormData = new FormData();
      csvFormData.append("csv-data", this.file);
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/pages/concession/bulk-upload`,
          csvFormData,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          if (response.data.length) {
            this.uploadErrors = response.data;
            this.makeToast(
              "danger",
              "Error",
              "Errors found while uploading data!"
            );
          } else {
            this.makeToast("success", "Success", `Uploaded successfully!`);
          }
        });
    },
  },
};
</script>

<style>
</style>